

import { useRoute } from 'vue-router'
import { ConfigValue, remoteConfig } from '@/services/RemoteConfig'
import { defineComponent } from 'vue'
import { createDynamicLink } from '@/services/DynamicLink'
import { isMobile } from 'mobile-device-detect'
import { logDownloadBadgeClick, mobileDynamicOpen, mobileDynamicItemOpen } from '@/services/EventLogger'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      scope: 'locale'
    })

    return { isMobile, t }
  },

  mounted() {
    if (isMobile) {
      const itemID = useRoute().params.itemId

      if (itemID) {
        // Dynamic Link should redirect to ITEM:
        // console.log('create dynamic link from... ' + itemID)
        mobileDynamicItemOpen()
        const dynLink = createDynamicLink(itemID as string)
        console.log(dynLink)
        window.location.href = dynLink
      } else {
        mobileDynamicOpen()
        // console.log('using default dynamic link ' + remoteConfig.getValue(ConfigValue.defaultDynamicLink).asString())
        window.location.href = remoteConfig.getValue(ConfigValue.defaultDynamicLink).asString()
      }
    }
  },

  methods: {
    downloadBadgeClick() {
      logDownloadBadgeClick()
    }
  }
})
